


// Call To action-2


.cta-2 {
	padding:  0px 0px 6.5rem 0px;
}


// ABout
// 

.about{
	position: relative;
	.about-img {
		img{
			box-shadow: 0px 20px 40px 0px rgba(38, 59, 94, 0.1);
		}
	}
	
}


//  Cta 

.cta-home{
	background:#F4F7FC;
}

.featured-client{
	img{
		margin: 0px 15px;
	}
}


.chapter-item{
	margin-bottom: 30px;
	h4{
		margin-bottom: 10px;
		text-transform: capitalize;
		font-size: 18px;
		color: $primary-color;
	}

	i{
		font-size: 20px;
	}
}



// Book Preview
.book-preview {
	display: inline-block;
	max-width:400px;
	width:100%;
	position: relative;
}

.book-preview .background-device {
	width:100%;
	position: absolute;
}

/* Owl Slider */
.owl-book {
	width:80%;
	display: inline-block;
	margin:0 auto;
}

.owl-book .book-item {
	box-shadow:0px 0px 1px rgba(0,0,0,.2);
	margin-top: 48px;
	position: relative;
}

.owl-book .book-item .overlay {
	position: absolute;
	left:0;
	top:0;
	width:100%;
	height: 100%;
	opacity: 0;
	transition: all 0.3s ease;
	background: rgba(0,0,0,0.7);
}
.owl-book  .book-item:hover .overlay {
	opacity: 1;
}
.owl-book .book-item img {
	width:100%;
	// margin:15% auto;
	transition: all 0.3s ease;
	border-radius:4px;
}
.owl-book .book-item:hover img {
}
.owl-book .book-item .overlay > a {
	position: absolute;
	display: inline-block;
	left:calc(50% - 30px);
	top:calc(50% - 25px);
	font-size:24px;
	line-height: 50px;
	width:50px;
	height: 50px;
	text-align: center;
	background: white;
	border-radius:50%;
	box-shadow:0px 1px 3px rgba(0,0,0,.3);
	color:$secondary-color;
}

.owl-book .book-item .overlay > a:hover {
	color:white;
	background: $primary-color;
}

.owl-reviews .book-item {
	padding:0 15px;
}

.owl-reviews .owl-controls {
	position: absolute;
	width:100%;
	top:calc(50% - 30px);
}

.owl-reviews .owl-controls .owl-prev,
.owl-reviews .owl-controls .owl-next {
	position: absolute;
	font-size:36px;
	color:#bbb;
	transition: all 0.3s ease;
}
.owl-reviews .owl-controls .owl-prev:hover,
.owl-reviews .owl-controls .owl-next:hover {
	color:#484d53;
}
.owl-reviews .owl-controls .owl-prev {
	left:-40px;
}
.owl-reviews .owl-controls .owl-next {
	right:-40px;
}

@media(max-width:768px) {
	.owl-reviews .owl-controls {
		position: relative;
		text-align: center;
		top:-30px;
	}

	.owl-reviews .owl-controls .owl-prev,
	.owl-reviews .owl-controls .owl-next {
		position: relative;
		display: inline-block;
		font-size:36px;
	}
	.owl-reviews .owl-controls .owl-prev {
	}
	.owl-reviews .owl-controls .owl-next {
	}
}




.book-info{
	ul li{
		padding: 8px 30px;
		margin-bottom: 5px;
		font-weight: 700;
		i{
			color: $primary-color;
		}
	}
}


// others book


.book-item{
	position: relative;

	&:hover .hover-item{
		opacity: 1;
		visibility: visible;
	}

	.hover-item{
		position: absolute;
		content:"";
		width: 100%;
		height:100%;
		background: rgba(0,0,0,0.6);
		left: 0px;
		top:0px;
		display: flex;
		justify-content: center;
		align-items: center;
		opacity: 0;
		visibility: hidden;
		-webkit-transition: all .35s ease;
		   -moz-transition: all .35s ease;
		    -ms-transition: all .35s ease;
		     -o-transition: all .35s ease;
		        transition: all .35s ease;

		i{
			width: 60px;
			height:60px;
			background: $light;
			color: $black;
			text-align: center;
			display: inline-block;
			border-radius: 100%;
			padding-top: 20px;
			-webkit-transition: all .35s ease;
			   -moz-transition: all .35s ease;
			    -ms-transition: all .35s ease;
			     -o-transition: all .35s ease;
			        transition: all .35s ease;
			&:hover{
				background: $primary-color;
				color: $light;
			}

		}
	}
}


/*******************************
* Does not work properly if "in" is added after "collapse".
*******************************/
    .panel-group .panel {
        border-radius: 0;
        box-shadow: none;
        border-bottom:1px solid rgb(235, 235, 235);
    }

    .panel-default > .panel-heading {
        padding: 0;
        border-radius: 0;
        color: #212121;
    }

    .panel-title {
        font-size: 20px;
        font-weight: 400;
    }

    .panel-title > a {
        display: block;
        padding: 15px;
        text-decoration: none;
    }

    .more-less {
        float: right;
        color: #212121;
    }

    .panel-default > .panel-heading + .panel-collapse > .panel-body {
        border-top-color: #EEEEEE;
    }

    .panel-body{
    	padding: 20px;
    }

    // Counter Section
    

    .counter-item{
		.content h2{
			font-family: $secondary-font;
			font-size: 40px;
		}

		i{
			width: 70px;
			height:70px;
			border:1px solid #ddd;
			display: inline-block;
			border-radius: 100%;
			text-align: center;
			padding-top: 22px;
			color: $primary-color;
			font-size: 22px;
		}
    }

///Responsive
///
 

 @include mobile{
 	.owl-book .item img{
		width: 92%;
		margin: 0px;
	}

	.featured-client img {
	    margin: 20px 0px;
	}

 }
 @include tablet{
 	.book-info{
 		padding-top: 100px;
 	}
 	.featured-client img {
	    margin: 20px 0px;
	}

 }

 @include mobile-xs{

	.owl-book .item img {
	    width: 69%;
	    margin: 0px;
	}
	.owl-book .item {
	    margin-top: 35px;
	}
	.featured-client img {
	    margin: 20px 0px;
	}
 	
 }

