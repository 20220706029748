
/*----------------------
 FOOTER
-----------------------*/

.footer{
    padding: 20px 0px;
}


.footer-copy {
    font-size: 16px;
}

.current-year {
    color: $primary-color !important;
}




.text-black{
    color: $black;
}


/*-----------------------------
 SCROLL TO TOP
--------------------------------*/

.scroll-to-top{
    position: fixed;
    content: "";
    right: 30px;
    bottom: 50px;
    z-index: 999;
    background: $primary-color;
    color: $light!important;
    border-radius: 100%;
    opacity: 0;
    
    i{
        width: 60px;
        height: 60px;
        border-radius: 100%;
        text-align: center;
        font-size: 22px;
        line-height: 60px;
        display: block;
         -webkit-box-shadow: 0 7px 22px rgba(0, 0, 0, 0.08);
        box-shadow:0 7px 22px rgba(0, 0, 0, 0.08) ;
    }
    
}


.scroll-to-top:hover, .scroll-to-top:focus{
    text-decoration: none;
}
