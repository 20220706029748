
/*----------------------
 BANNER STYLE
-----------------------*/

.py-7 {
    padding: 7.5rem 0;
}


/*-------------------------
 HOME
-----------------------------*/
.banner-main{
    position: relative;
}


h1{
    font-size: 60px;
    line-height: 70px;
    color: #263b5e;
}

.main-banner {
    padding: 60px 0px 90px;

    p{
    	padding-right: 100px;
    }
}


// Responsive


@include mobile{
    h1 {
        font-size: 45px;
        line-height: 55px;
    }
    .main-banner p {
        padding-right: 0px;
    }
    
}
@include mobile-xs{
    h1 {
        font-size: 36px;
        line-height: 46px
    }
    .main-banner p {
        padding-right: 0px;
    }
   
}

