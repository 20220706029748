/*------------------------------------
SECTION TESTIMONIAL
-------------------------------------*/
.testimonial{
    position: relative;
    background:#F4F7FC;
}
.testimonial-item-content{
    text-align: center;
    -webkit-transition: all .7s ease;
    -o-transition:all .7s ease ;
    transition:all .7s ease ;
    position: relative;
    padding:40px  30px;
    &:hover{
        cursor: pointer;
    }
}


.test-author-thumb{
    img{
        margin: 0 auto;
        width: 80px;
        height:80px;
        border-radius: 100%;
    }
}



.rating{
    a{
        color: $primary-color;
    }
}


// Testimonial-2

.testimonial2 p {
  font-size: 20px;
  color: #000;
  line-height: 38px;
}

.testimonial2 .author {
  text-align: center;
}

.testimonial2 .author img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0 auto;
}

.testimonial2 .author .author-info {
  margin-top: 20px;
}

.testimonial2 .author h4 {
  font-size: 18px;
  margin-bottom: 0;
}

.testimonial2 .author p {
  font-size: 14px;
}

// .