.service-block{
	padding: 60px 40px;
	text-align: center;
	 -webkit-transition: all 0.3s ease-in-out;
       -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
         -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;


	&:hover{
		background: $light;
		box-shadow: 12px 7px 90px 0 rgba(110,123,131,.1);
	}

	i{
		font-size: 60px;
	}

}



.feature-block{
	padding: 60px 40px;
	text-align: center;
	box-shadow: 12px 7px 90px 0 rgba(110,123,131,.1);
	background: $light;
	i{
		font-size: 60px;
	}

}


.feature{
    margin-top: -80px;
}