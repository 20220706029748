$light: #fff;
$primary-color:#cc302b;
$secondary-color: #385777;
$black: #222;
$black-light: #3f4451;
$black-lighter: #888;
$border-color:#eee;
$primary-font: 'Raleway', sans-serif;
$secondary-font:'Open Sans', sans-serif;
$gray : #f7fdfc;
